import ReactGA4 from "react-ga4";
const initializeGoogleAnalytics = () => {
  ReactGA4.initialize("G-8B3411WMTS");
};

const formPaymentAnalyse = () =>
  ReactGA4.event({
    category: "Form analyse",
    action: "Form_step_7_payment_success",
    label: "Payment",
  });

const formFirstPageAnalyse = () =>
  ReactGA4.event({
    category: "Form analyse",
    action: `Form_step_0_address`,
    label: "Payment",
    value: 0,
  });

const formPagesAnalyse = (stepCount) => {
  let currentId;

  console.log(stepCount);
  console.log(currentId);

  switch (stepCount) {
    case 1:
      currentId = "1_move_date";
      break;
    case 2:
      currentId = "2_notifications";
      break;
    case 3:
      currentId = "3_notifications_select_service";
      break;
    case 4:
      currentId = "4_suppliers";
      break;
    case 5:
      currentId = "5_create_account";
      break;
    case 6:
      currentId = "6_payment_screen";
      break;
    case 7:
      currentId = "7_payment_success";
      break;

    default:
      currentId = stepCount;
  }

  return ReactGA4.event({
    category: "Form analyse",
    action: `form_step_${currentId}`,
    label: "Payment",
    value: stepCount,
  });
};

export {
  initializeGoogleAnalytics,
  formPaymentAnalyse,
  formFirstPageAnalyse,
  formPagesAnalyse,
};
